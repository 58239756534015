import React from "react";
import theme from "theme";
import { Theme, Text, Section, Icon, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Contact Us | Meadowbrook Golf Haven
			</title>
			<meta name={"description"} content={"Meadowbrook Golf Haven offers a tranquil golfing experience with pristine greens, unique signature holes, and dedicated training from experienced instructors. Find your golf escape in our serene settings."} />
			<meta property={"og:title"} content={"Contact Us | Meadowbrook Golf Haven"} />
			<meta property={"og:description"} content={"Meadowbrook Golf Haven offers a tranquil golfing experience with pristine greens, unique signature holes, and dedicated training from experienced instructors. Find your golf escape in our serene settings."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/ball_1.jpg?v=2023-10-24T09:08:20.037Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0 0px 0"
			sm-padding="40px 0"
		>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Join Us
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Reserve a tee time or learn about our training programs today. Experience golf the Meadowbrook way.
			</Text>
		</Section>
		<Section
			color="--light"
			padding="30px 0 100px 0"
			sm-padding="40px 0"
			position="relative"
			background="--color-primary"
			quarkly-title="Form-1"
		>
			<Box margin="0px -16px 0px -16px" display="block" flex-wrap="wrap">
				<Box
					display="flex"
					align-self="center"
					flex-wrap="no-wrap"
					justify-content="space-between"
					align-items="flex-start"
					align-content="flex-end"
					flex-direction="row"
					sm-display="block"
				>
					<Box
						sm-padding="64px 0 0 0"
						margin="0 0 0 0"
						max-width="360px"
						position="relative"
						padding="0 0 0 64px"
					>
						<Icon
							size="48px"
							top="0"
							left="0"
							category="md"
							icon={MdLocationOn}
							position="absolute"
						/>
						<Text as="h4" margin="6px 0" font="--base">
							Location
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
							99 Marx Meadow Dr, San Francisco, CA 94121, United States
						</Text>
					</Box>
					<Box padding="0 0 0 64px" margin="0 0 0 0" max-width="360px" position="relative">
						<Icon
							left="0"
							category="md"
							icon={MdPhone}
							position="absolute"
							size="48px"
							top="0"
						/>
						<Text font="--base" as="h4" margin="6px 0">
							Phone
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
							+1 415-831-27-13
							<br />
							+1 415-831-27-00
						</Text>
					</Box>
					<Box
						position="relative"
						padding="0 0 0 64px"
						sm-padding="64px 0 0 0"
						margin="0 0 0 0"
						max-width="360px"
					>
						<Icon
							top="0"
							left="0"
							category="md"
							icon={MdEmail}
							position="absolute"
							size="48px"
						/>
						<Text font="--base" as="h4" margin="6px 0">
							Email
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
							<Link href="mailto:contact@bojokua.com" text-decoration="none" hover-text-decoration="underline" color="--light">
								contact@bojokua.com
							</Link>
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});